import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";
import { DateUtils } from "@utils/date-utils";
import { TitleCasePipe } from "../pipes/title-case.pipe";

@Component({
  selector: "dropdown",
  template: `<select [id]="nameOrId" [(ngModel)]="value" [name]="nameOrId" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" [class]="cssClass">
            <option *ngIf="!showBlankFirstOption || !isrequired"></option>
            <option *ngFor="let v of values" [ngValue]="_getValue(v)">{{_getValueText(v)}}</option>
        </select>`
})

export class DropdownComponent {
  _fieldId: string;
  _filter: TitleCasePipe = new TitleCasePipe();

  @Input() cssClass: string;
  @Input() name: string;
  @Input() value: any;
  @Input() values;
  @Input() valuetype: string = "object.idname.int";
  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() showBlankFirstOption: boolean = true;
  @Input() tooltip: string;
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  @Input() filter: string;

  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  _getValueText(value) {
    switch (this.valuetype) {
      case "simple.string":
        return this._transformValue(value);

      case "simple.int":
        return value.toString();

      case "object.date":
        var date = value;
        try {
          if (!(date instanceof Date)) {
            date = DateUtils.parseJsonDate(value);
          }
          if (date != null && (date instanceof Date)) {
            return DateUtils.formattedDateString(date);
          }
          return DateUtils.formattedDateString(date);
        } catch (ex) {
          console.error("Error parsing " + date);
          return value;
        }

      case "object.code":
      case "object.idname":
      case "object.idname.int":
      default:
        return this._transformValue(value.name);
    }
  }

  _transformValue(value: string) {
    if (this.filter == null || this.filter != "title-case") {
      return value;
    }
    return this._filter.transform(value);
  }

  _getValue(value) {
    switch (this.valuetype) {
      case "simple.int":
        return value;

      case "simple.string":
        return value;

      case "object.date":
        return <Date>value;

      case "object.code":
        return value.code;

      case "object.idname":
      case "object.idname.int":
      default:
        return value.id;
    }
  }

  onChange(newValue) {
    let isNull = newValue == null;
    let isTrue = !isNull && newValue === true;
    switch (this.valuetype) {
      case "simple.int":
        this.value = parseInt(newValue);
        break;

      case "object.idname.int":
      case "int":
        this.value = parseInt(newValue);
        break;

      case "bool":
        this.value = isTrue;
        break;

      case "bool?":
        this.value = isNull ? null : isTrue;
        break;

      case "object.code":
        this.value = isNull ? null : newValue.code;

      default:
        this.value = isNull ? null : newValue;
        break;
    }

    if (this.callback != null) {
      this.callback.emit(this.value);
    }

    this.valueChange.emit(this.value);
  }

  get nameOrId() {
    return this.name == null ? this._fieldId : this.name;
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}
