import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-required-input",
  template: `
    <form-row [isrequired]="true">
      <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
      <input required
          [id]="_fieldId" 
          [class]="inputCss"
          [class.uk-form-danger]="!isValid" 
          [type]="type" 
          [placeholder]="title"
          [(ngModel)]="value" 
          (ngModelChange)="onChange($event)"
          [ngModelOptions]="{standalone: true}"
          [auto-complete]="autocomplete" />
      <ng-content></ng-content>
    </form-row>`
})

export class LabelAndRequiredInputComponent {
  _fieldId: string;

  @Input() value: string;
  @Input("input-css") inputCss: string;
  @Input() title: string;
  @Input() tooltip: string;
  @Input() type: string = "text";
  @Input() autocomplete: boolean = null;

  private _disableValidation: boolean = false;
  @Input("disable-validation") set disableValidation(value: boolean) {
    this._disableValidation = value;
    this.validate();
  }
  get disableValidation() {
    return this._disableValidation;
  }

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  private _isValid: boolean = true;
  public get isValid() {
    return this._isValid;
  }

  validate() {
    var result = this.disableValidation || (!this.disableValidation && this.value != null && this.value.length > 0);
    if (result && this.type == "email") {
      var input = document.getElementById(this._fieldId) as HTMLFormElement;
      if (input != null) {
        this._isValid = input.checkValidity();
        return;
      }
    }

    this._isValid = result;
  }

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
    this.validate();
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("reqinput");
  }

}