import { Component, Input } from "@angular/core";
import { Filter } from "./filter";
import { FilterOperator } from "./filter-operator";

@Component({
    selector: "number-filter",
    template: `
    <label>{{propertyName}}</label>
    <select [(ngModel)]="_operator">
            <option *ngFor="let v of _operators" [ngValue]="v">{{_getOperatorName(v)}}</option>
    </select>
    <input size="6" type="text" [(ngModel)]="_comparsionValue" />
    <input size="6" *ngIf="_showComparisonValue2" type="text" [(ngModel)]="_comparsionValue2" />
`
})

export class NumberFilterComponent  {
    @Input() propertyName: string;
    _operator: FilterOperator;
    _operators = Filter.filters.number;
    _comparsionValue: number;
    _comparsionValue2: number;

    public compare( propertyValue: number ) {
        return Filter.compareNumber(propertyValue, this._operator, this._comparsionValue, this._comparsionValue2);
    }

    private _getOperatorName(operator: FilterOperator) {
        return Filter.operatorName(operator);
    }

    get _showComparisonValue2() {
        return this._operator == FilterOperator.Between || this._operator == FilterOperator.NotBetween;
    }

    public get isSelected() {
        return this._operator != null;
    }
}
