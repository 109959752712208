import { Pipe, PipeTransform } from "@angular/core";
import { DateUtils } from "@utils/date-utils"

@Pipe({ name: "parsedate" })
export class ParseDatePipe implements PipeTransform {
    transform(value: string, format: string): string {
        try {
            let date = DateUtils.parseJsonDate(value);
            return date == null ? "" :
                format == null ?
                    DateUtils.formattedDateString(date) :
                    DateUtils.formattedDateTimeString(date);
        } catch (ex) {
            return "Error with " + value;
        }
    }
}
