import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-native-date-range",
  template: `
    <form-row [isrequired]="isrequired">
      <label-and-tooltip [fieldid]="_fieldId + '_from'" [title]="title" [tooltip]="tooltip" *ngIf="title != null && title != ''"></label-and-tooltip>
      <native-date-picker 
            [name]= "_fieldId + '_from'" 
            [(min)]="fromMin"
            [(max)]="fromMax"
            [(value)]="from" 
            (valueChange)="fromChange.emit($event)">
      </native-date-picker>

      &nbsp;to&nbsp;

      <native-date-picker 
            [name]= "_fieldId + '_to'" 
            [(min)]="toMin"
            [(max)]="toMax"
            [(value)]="to" 
            (valueChange)="toChange.emit($event)">
      </native-date-picker>
    </form-row>`
})

export class LabelAndNativeDateRangeComponent {
  _fieldId: string;

  @Input() from: string; //ISO Date Format
  @Output() fromChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() to: string; //ISO Date Format
  @Output() toChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() tooltip: string;

  @Input("from-min") fromMin: string = "text";
  @Input("from-max") fromMax: string = "text";

  @Input("to-min") toMin: string = "text";
  @Input("to-max") toMax: string = "text";

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }
}