import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";
import { DateUtils } from "@utils/date-utils";

@Component({
  selector: "native-date-picker",
  template: `
    <input [id]="nameOrId" 
           [name]="nameOrId" 
           type="date" 
           [(min)]="min"
           [(max)]="max"
           [(ngModel)]="textValue" 
           (ngModelChange)="onChange($event)"
           [ngModelOptions]="{standalone: true}" />`
})

export class NativeDatePickerComponent {
  _fieldId: string;


  //Note that if you pass a date then it get converted into a culture specific string which can cause problems
  //e.g. 2018-02-02T00:00:00 would be translated into 2018-02-01T22:00:00
  @Input() value: string; //ISO Date Format


  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  onChange(newValue) {
    this.textValue = newValue;
    this.value = newValue + "T00:00:00";
    this.valueChange.emit(this.value);
  }

  get textValue(): string {
    var date = DateUtils.parseJsonDate(this.value);
    if (date == null) {
      return null;
    }

    return DateUtils.yearMonthDayDateString(date);
  }

  //Needed?
  @Input() set textValue(value: string) {
    this.value = value;
  }

  @Input() name: string = "";
  @Input() min: string = "";
  @Input() max: string = "";
  
  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }

  get nameOrId(): string {
    return this.name != null ? this.name : this._fieldId;
  }

  private _getInput() {
    var input = document.getElementById(this._fieldId);
    if (input == null) {
      return;
    }
    return <HTMLInputElement>input;
  }

  private padNum = num => num < 10 ? "0" + num : num.toString();

  parseDate(value: string) {
    if (value == null || value.length < 8) {
      return null;
    }
    var date = DateUtils.parseFormattedDate(value);
    if (date == null) {
      return null;
    }

    return DateUtils.unspecifiedDateString(date);
  }
}
