import { Component, Input } from "@angular/core";
import { SecurityCache } from "@models/security-cache";
import { Permission } from "@models/permission";

@Component({
  selector: "blackbox-actions-button",
  templateUrl: "./blackbox-actions-button.component.html"
})

export class BlackBoxActionsButtonComponent {
  routePrefix: string;
  @Input() text: string = "menu";
  @Input() mode: string = "{ mode: 'click' }";
  @Input() useButton: boolean;

  constructor(private _securityCache: SecurityCache) {
  }

  private _blackBox: string;

  @Input() set blackbox(value: string) {
    this.routePrefix = `/logged-in/management/rating/${value.toLowerCase().replace("/", "")}/`;
    this._blackBox = value;
  }

  hasPermission(area: string) {
    return this._securityCache.hasPermission(this._blackBox, area, Permission.read);
  }

}
