import { Component,  Input } from "@angular/core";
import { Filter } from "./filter";
import { FilterOperator } from "./filter-operator";

@Component({
    selector: "string-filter",
    template: `
    <label>{{propertyName}}</label>
    <select [(ngModel)]="_operator">
            <option *ngFor="let v of _operators" [ngValue]="v">{{_getOperatorName(v)}}</option>
    </select>
    <input type="search" [(ngModel)]="_comparsionValue" />
`
})

export class StringFilterComponent {
    @Input() propertyName: string;
    _operator: FilterOperator;
    _operators = Filter.filters.string;
    _comparsionValue: string;

    public compare(propertyValue: string) {
      return Filter.compareString(propertyValue.toLowerCase(), this._operator, this._comparsionValue.toLowerCase());
    }

    private _getOperatorName(operator: FilterOperator) {
        return Filter.operatorName(operator);
    }

    public get isSelected() {
        return this._operator != null;
    }
}
