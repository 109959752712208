//Based on https://github.com/gmostert/ng2-breadcrumb
import { Injectable } from "@angular/core";
import { Cache } from "@models/cache";
@Injectable()
export class BreadcrumbService {

  private routesFriendlyNames: Map<string, string> = new Map<string, string>();
  private routesFriendlyNamesRegex: Map<string, string> = new Map<string, string>();
  private routesWithCallback: Map<string, any> = new Map<string, any>();
  private routesWithCallbackRegex: Map<string, any> = new Map<string, any>();
  private hideRoutes: Array<string> = new Array<string>();
  private hideRoutesRegex: Array<string> = new Array<string>();

  constructor(public cache: Cache) {
  }

  /**
   * Specify a friendly name for the corresponding route.
   *
   * @param route
   * @param name
   */
  addFriendlyNameForRoute(route: string, name: string): BreadcrumbService {
    this.routesFriendlyNames.set(route, name);
    return this;
  }

  /**
   * Specify a friendly name for the corresponding route matching a regular expression.
   *
   * @param route
   * @param name
   */
  addFriendlyNameForRouteRegex(routeRegex: string, name: string): BreadcrumbService {
    this.routesFriendlyNamesRegex.set(routeRegex, name);
    return this;
  }

  /**
   * Specify a callback for the corresponding route.
   * When a matching url is navigated to, the callback function is invoked to get the name to be displayed in the breadcrumb.
   */
  addCallbackForRoute(route: string, callback): BreadcrumbService {
    this.routesWithCallback.set(route, callback);
    return this;
  }

  /**
   * Specify a callback for the corresponding route matching a regular expression.
   * When a matching url is navigated to, the callback function is invoked to get the name to be displayed in the breadcrumb.
   */
  addCallbackForRouteRegex(routeRegex: string, callback): BreadcrumbService {
    this.routesWithCallbackRegex.set(routeRegex, callback);
    return this;
  }

  /**
   * Show the friendly name for a given route (url). If no match is found the url (without the leading '/') is shown.
   *
   * @param route
   * @returns {*}
   */
  getFriendlyNameForRoute(route: string, item: BreadcrumbItem) {
    if (route == null) {
      return;
    }

    route = route.toString(); //in case it's a UrlTree

    let routeEnd = route.substr(route.lastIndexOf('/') + 1, route.length);
    item.name = routeEnd.charAt(0).toUpperCase() + routeEnd.slice(1);

    this.routesFriendlyNames.forEach((value, key, map) => {
      if (key === route) {
        item.name = value;
      }
    });

    this.routesFriendlyNamesRegex.forEach((value, key, map) => {
      if (new RegExp(key).exec(route)) {
        item.name = value;
      }
    });

    this.routesWithCallback.forEach((value, key, map) => {
      if (key === route) {
        value(routeEnd, item);
      }
    });

    this.routesWithCallbackRegex.forEach((value, key, map) => {
      var result = new RegExp(key).exec(route);
      if (result) {
        value(routeEnd, item, result);
      }
    });

    //item.name = name ? name : routeEnd;
  }

  /**
   * Specify a route (url) that should not be shown in the breadcrumb.
   */
  hideRoute(route: string): BreadcrumbService {
    //if (!this.hideRoutes.includes(route)) {
    if (this.hideRoutes.indexOf(route) == -1) {
      this.hideRoutes.push(route);
      return this;
    }
  }

  /**
   * Specify a route (url) regular expression that should not be shown in the breadcrumb.
   */
  hideRouteRegex(routeRegex: string): BreadcrumbService {
    //if (!this.hideRoutesRegex.includes(routeRegex)) {
    if (this.hideRoutesRegex.indexOf(routeRegex) == -1) {
      this.hideRoutesRegex.push(routeRegex);
      return this;
    }
  }

  /**
   * Returns true if a route should be hidden.
   */
  isRouteHidden(route: string): boolean {
    //let hide = this.hideRoutes.includes(route);
    let hide = this.hideRoutes.indexOf(route) > -1;

    this.hideRoutesRegex.forEach((value) => {
      if (new RegExp(value).exec(route)) {
        hide = true;
      }
    });

    return hide;
  }
}

export class BreadcrumbItem {
  public name: string;
  public url: string;
}

