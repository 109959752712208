import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BaseApiService } from "@services/base-api.service"
import { LoginRequest } from "@models/login-request";
import { HttpHelper } from "@utils/http-helper";
import { Cache } from "@models/cache";
import { SecurityCache } from "@models/security-cache";

@Injectable()
export class AccountService extends BaseApiService {
  constructor(
    protected securityCache: SecurityCache,
    protected cache: Cache,
    http: HttpHelper,
    private _router: Router) {
    super(securityCache, http, "account");
  }

  public getPasswordRules(callback) {
    this.getAndCallback("password/rules", callback);
  }

  public resetPasswordCheck(resetId: string, callback) {
    let path = `password/reset/${resetId}/check`;
    return this.getAndCallback(path, callback);
  }

  public validatePassword(request, callback) {
    this.postAndCallback("password/validate", request, callback);
  }

  public changePassword(request, callback) {
    return this.putAndCallback("password/change", request, callback);
  }

  public sendSetPasswordEmail(username: string, callback) {
    let path = `password/username/${username}/set-email`;
    return this.getAndCallback(path, callback);
  }

  public resetPassword(request: any, callback) {
    let path = "password/reset";
    return this.postAndCallback(path, request, callback);
  }

  public login(loginRequest: LoginRequest, callback) {
    let self = this;
    let callBack = (result) => {
      self.securityCache.loginResult = result;
      callback(result);
    }

    return this.postAndCallback("login", loginRequest, callBack);
  }

  public logout() {
    let self = this;
    return this.getAndCallback("logout", () => {
      self.cache.clear();
      self.securityCache.loginResult = null;
      localStorage.removeItem(SecurityCache.userTokenKey);
      self._router.navigateByUrl("/");
    });
  }

  public validLandingPages(callback) {
    return this.getAndCallback("lookup/landingpages", callback);
  }

  public updateProfile(request, callback) {
    return this.putAndCallback("profile/update", request, callback);
  }

  public updatePassword(request, callback) {
    return this.putAndCallback("password/update", request, callback);
  }

  public impersonateUser(username: string, callback) {
    let self = this;
    let _callBack = (result) => {
      if (result != null) {
        self.securityCache.resetLoggedInUser(result);
      }
      callback(result);
    };

    return this.getAndCallback(`user/${username}/impersonate`, _callBack);
  }
}
