import { Component, Input, VERSION } from "@angular/core";
import { AppConfig } from "@models/app-config";

@Component({
  selector: "app-footer",
  template: `
<footer [class]="cssClassName">
    <span class="uk-float-left">
        Nimbis Rates WebApi v{{_version}}, 
        Angular v{{_angularVersion}}, 
        {{_environment}} Environment
        {{_warning}}
    </span>
    <section class="copyright">
        &copy; Copyright {{_year}} Concourse IT (Pty) Ltd. All rights reserved.
    </section>
</footer>`
})


export class FooterComponent {
  @Input() cssClassName;
  _environment: string;
  _version: string;
  _angularVersion: string;
  _year: number = new Date().getFullYear();
  _warning: string;

  constructor(appConfig: AppConfig) {
    let environ = appConfig.environment;
    this._environment = environ.charAt(0).toUpperCase() + environ.slice(1);
    this._version = appConfig.version;
    this._angularVersion = VERSION.full;
    if (window.location.hostname == "localhost" && this._environment == "Production") {
      this._warning = "DEVELOPMENT SITE IS USING PRODUCTION SERVICES!!";
      UIkit.modal.alert(this._warning);
    }
  }
}
