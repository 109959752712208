import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { FormIdService } from "@services/form-id.service";

import { AutoCompleteDirective } from "./autocomplete.directive";
import { DropdownComponent } from "./dropdown.component";
import { DropdownWithGroupsComponent } from "./dropdown-with-groups.component";
import { FormRowComponent } from "./form-row.component";
import { LabelAndBooleanComponent } from "./label-and-boolean.component";
import { LabelAndCheckboxListArrayComponent } from "./label-and-checkbox-list-array.component";
import { LabelAndCheckboxListComponent } from "./label-and-checkbox-list.component";
import { LabelAndCheckboxListComponent2 } from "./label-and-checkbox-list2.component";
import { LabelAndDatePickerComponent } from "./label-and-date-picker.component";
import { LabelAndDropdownComponent } from "./label-and-dropdown.component";
import { LabelAndInputComponent } from "./label-and-input.component";
import { LabelAndNativeDatePickerComponent } from "./label-and-native-date-picker.component";
import { LabelAndNativeDateRangeComponent } from "./label-and-native-date-range.component";
import { LabelAndNullableBooleanComponent } from "./label-and-nullable-boolean.component";
import { LabelAndNumberComponent } from "./label-and-number.component";
import { LabelAndObjectDropdownComponent } from "./label-and-object-dropdown.component";
import { LabelAndRequiredDropdownComponent } from "./label-and-required-dropdown.component";
import { LabelAndRequiredInputComponent } from "./label-and-required-input.component";
import { LabelAndTextareaComponent } from "./label-and-textarea.component";
import { LabelAndTooltipComponent } from "./label-and-tooltip.component";
import { NativeDatePickerComponent } from "./native-date-picker.component";

import { SearchInputComponent } from "./search-input.component";
import { TooltipIconComponent } from "./tool-tip-icon.component";

import { CancelFilterComponent } from "./cancel-filter.component";
import { NumberFilterComponent } from "./number-filter.component";
import { StringFilterComponent } from "./string-filter.component";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    AutoCompleteDirective,
    CancelFilterComponent,
    DropdownComponent,
    DropdownWithGroupsComponent,
    FormRowComponent,
    LabelAndBooleanComponent,
    LabelAndCheckboxListComponent2,
    LabelAndCheckboxListArrayComponent,
    LabelAndCheckboxListComponent,
    LabelAndDatePickerComponent,
    LabelAndDropdownComponent,
    LabelAndInputComponent,
    LabelAndNativeDatePickerComponent,
    LabelAndNativeDateRangeComponent,
    LabelAndNullableBooleanComponent,
    LabelAndNumberComponent,
    LabelAndObjectDropdownComponent,
    LabelAndRequiredDropdownComponent,
    LabelAndRequiredInputComponent,
    LabelAndTextareaComponent,
    LabelAndTooltipComponent,
    NativeDatePickerComponent,
    NumberFilterComponent,
    SearchInputComponent,
    StringFilterComponent,
    TooltipIconComponent
  ],
  providers: [FormIdService],
  exports: [
    CommonModule,
    FormsModule,

    AutoCompleteDirective,
    CancelFilterComponent,
    DropdownComponent,
    DropdownWithGroupsComponent,
    FormRowComponent,
    LabelAndBooleanComponent,
    LabelAndCheckboxListComponent2,
    LabelAndCheckboxListArrayComponent,
    LabelAndCheckboxListComponent,
    LabelAndDatePickerComponent,
    LabelAndDropdownComponent,
    LabelAndInputComponent,
    LabelAndNativeDatePickerComponent,
    LabelAndNativeDateRangeComponent,
    LabelAndNullableBooleanComponent,
    LabelAndNumberComponent,
    LabelAndObjectDropdownComponent,
    LabelAndRequiredDropdownComponent,
    LabelAndRequiredInputComponent,
    LabelAndTextareaComponent,
    LabelAndTooltipComponent,
    NativeDatePickerComponent,
    NumberFilterComponent,
    SearchInputComponent,
    StringFilterComponent,
    TooltipIconComponent
  ]
})

export class FormInputsModule { }