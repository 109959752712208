import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SecurityCache } from "@models/security-cache";
import { AppRoutes } from "../app-routes";
import { AccountService } from "@services/account.service";
import { AppConfig } from "@models/app-config";
import { interval } from "rxjs";
import { SimpleLogInFormComponent } from "../account/simple-login-form.component";
import { ButtonWithSpinnerComponent } from "@shared/button-with-spinner.component";
import { UiHelper } from "@utils/ui-helper";

@Component({
  selector: "app-simple-loggedin",
  templateUrl: "./logged-in.component.html"
})

export class LoggedInComponent implements OnInit {
  public user;
  private _storageKey: string = "app:username";
  private _tokenKey: string = "app:usertoken";
  protected storedUsername: string;
  protected storedToken: string;
  private _hasStoredToken: boolean = false;
  private _checkIsLoggedIn: boolean = false;
  private _checkIsLoggedInInterval = 60000; //one minute

  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _cache: SecurityCache,
    private _appConfig: AppConfig) {
  }

  ngOnInit() {
    var self = this;

    this._accountService.isLoggedIn(() => {
      self.user = self._accountService.loggedInUser;
      //self._cache.loggedInUser = self.user;
      self._checkIsLoggedIn = true;
      self.storedUsername = localStorage.getItem(this._storageKey);
      self.storedToken = localStorage.getItem(this._tokenKey);
      if (self.storedToken != null) {
        self.storedToken = self.storedToken.toUpperCase();
        self._hasStoredToken = true;
      }
    }, () => self._router.navigateByUrl(AppRoutes.Login));

    const checkCounter = interval(this._checkIsLoggedInInterval);

    checkCounter.subscribe(() => {
      //console.log("Check if is logged in?");
      if (self._checkIsLoggedIn) {
        //console.log("Checking");
        self._accountService.isLoggedIn(() => {
          //console.log("Logged in");
        }, () => {
          //console.log("Not logged in");
          self._toggleLoginModal(true);
        }).catch(() => {});
      }
    });
  }

  logout() {
    UIkit.modal.confirm("Are you sure?", () => {
      this._router.navigateByUrl(AppRoutes.Logout);
    });
  }

  private _modal: UIkit.ModalElement;
  get modal() {
    if (this._modal == null) {
      this._modal = UIkit.modal("#loginModal", { keyboard: false, bgclose: false });
    }
    return this._modal;
  }

  loginHandler(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this._toggleLoginModal(false);
      UiHelper.notifySuccess("Logged in");
    }
  }

  login(button: ButtonWithSpinnerComponent, form: SimpleLogInFormComponent) {
    form.login(button);
  }

  _blur(shouldBlur: boolean) {
    if (this._hasStoredToken) {
      return;
    }

    let blur = shouldBlur ? 5 : 0;
    let grayscale = shouldBlur ? 100 : 0;

    if (this._wrapper == null) {
      this._wrapper = document.getElementById("wrapper");
    }
    if (this._wrapper == null) {
      return;
    }
    this._wrapper.style.filter = `blur(${blur}px) grayscale(${grayscale}%)`;
  }

  _wrapper;
  _toggleLoginModal(show: boolean) {
    this._blur(show);
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
    this._checkIsLoggedIn = !show;
  }
}
