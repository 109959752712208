import { Component } from "@angular/core";

@Component({
  selector: "app-account-password-set-expired",
  template: `<alert-message is-centered="true" width="small-1-3" type="info">
<i class="uk-icon-exclamation-triangle"></i> 
Your password has expired and must be changed.</alert-message>
<app-account-password-set-or-change mode="set"></app-account-password-set-or-change>`
})

export class ExpiredPasswordComponent {
}
