import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-textarea",
  template: `
    <form-row [isrequired]="isrequired" [extraCss]="'textarea-row'">
        <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
        <textarea [id]="_fieldId"
            [cols]="cols"
            [rows]="rows"
            [class]="inputCss"
            [(ngModel)]="value"
            (ngModelChange)="onChange($event)"
            [ngModelOptions]="{standalone: true}"
            placeholder="{{title}}"></textarea>
    </form-row>`
})

export class LabelAndTextareaComponent {
  _fieldId: string;

  @Input() cols: number = 40;
  @Input() rows: number = 6;
  @Input() value: string;
  @Input() title: string;
  @Input("input-css") inputCss: string;
  @Input() tooltip: string;
  @Input() isrequired: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }
}