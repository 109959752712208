import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: '[auto-complete]'
})

export class AutoCompleteDirective {

  constructor(private _elementRef: ElementRef) { }

  @Input("auto-complete") value: boolean = false;

  ngAfterViewInit() {
    if (this.value === false) {
      this._elementRef.nativeElement.autocomplete = "off";
    }
    if (this.value === true) {
      this._elementRef.nativeElement.autocomplete = "on";
    }
  }
}