import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";
import { DateUtils } from "@utils/date-utils"

@Component({
  selector: "label-and-object-dropdown",
  template: `
    <form-row [isrequired]="isrequired">
        <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
        <select [id]="_fieldId" [(ngModel)]="value" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}">
            <option *ngIf="!isrequired"></option>
            <option *ngFor="let v of values" [ngValue]="v">{{_valueText(v)}}</option>
        </select>
    </form-row>`
})

export class LabelAndObjectDropdownComponent {
  _fieldId: string;

  @Input() value;
  @Input() values;
  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() tooltip: string;
  @Input() valuetype: string;

  @Output() valueChange = new EventEmitter();

  _valueText(value) {
    switch (this.valuetype) {
      case "date":
        var date = DateUtils.parseJsonDate(value);
        try {
          if (date != null) {
            return DateUtils.formattedDateString(date);
          }
          return value;
        } catch (ex) {
          console.error("Error parsing " + date);
          return value;
        }

      default:
        return value.name;
    }
  }

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}
