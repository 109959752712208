import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "blackboxname" })
export class BlackBoxNamePipe implements PipeTransform {
  transform(value: string): string {
    switch (value.toLowerCase()) {

    case "axsure":
        return "Axsure";

      case "crosscountry":
        return "Cross Country";

      case "renasacommercial":
        return "Renasa Commercial";

      case "renasasure":
        return "Renasa Domestic";

      case "medipet":
        return "MediPet";

      case "mizizi":
        return "Mizizi";

      case "safireagricultural":
        return "Safire Agricultural";

      case "safiredomestic":
        return "Safire Domestic";

      case "safirecommercial":
        return "Safire Commercial";

      default:
        return value.length === 3 ? value.toUpperCase() : value;
    }
  }
}
