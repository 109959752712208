export class Permission {
  static none = 0;
  static create = 1;
  static read = 2;
  static update = 4;
  static delete = 8;
  static execute = 16;


  static contains(listOfPermissions, permission) {
    for (let p of listOfPermissions) {
      if (p === permission) {
        return true;
      }
    }

    return false;
  }
}

export class PermissionArea {
  static logs = "management.logs";
  static errorLogs = "error-logs";
  static management = "management";
  static baseLayers = "management.base-layers";
  static brokerLoadings = "management.broker-loadings";
  static inMemoryVersions = "management.in-memory-versions";
  static loadings = "management.loadings";
  static currentLoadings = "management.loadings.current";
  static pastLoadings = "management.loadings.past";
  static loadingTypeOptionApplicability = "management.loading-type-option-applicability";
  static loadingTypeOptions = "management.loading-type-options";
  static loadingTypes = "management.loading-types";
  static products = "management.products";
  static refreshContext = "management.refresh-context";
  static sections = "management.sections";
  static versions = "management.versions";
  static loadingsReport = "management.versions.report";
  static rootAccess = "root";
  static tools = "tools";
  static jsonTester = "tools.json-tester";
  static legacyXmlTester = "tools.legacy-tester";
  static restartSite = "tools.restart";
  static userAdmin = "admin.user";
  static admin = "admin";
  static adminLogs = "admin.logs";
}
