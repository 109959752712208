import { Component, Input } from "@angular/core";

@Component({
  selector: "label-and-tooltip",
  template: `
    <label [attr.for]="fieldid" [attr.data-uk-tooltip]="tooltip">
      {{title}}
      <tooltip-icon [tooltip]="tooltip"></tooltip-icon>
    </label>`
})

export class LabelAndTooltipComponent {
  @Input()
  fieldid: string;

  @Input()
  title: string;

  @Input()
  tooltip: string;
}