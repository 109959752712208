import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AccountComponent } from "./account.component";
import { LogInComponent } from "./login.component";
import { LogOutComponent } from "./logout.component";

import { ForgottenPasswordComponent } from "./password/forgotten-password.component";
import { ResetPasswordComponent } from "./password/reset-password.component";
import { SetPasswordComponent } from "./password/set-password.component";
import { ExpiredPasswordComponent } from "./password/expired-password.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: AccountComponent,
        children: [
          { path: "login", component: LogInComponent },
          { path: "logout", component: LogOutComponent },
          {
            path: "password",
            children: [
              { path: "forgotten", component: ForgottenPasswordComponent },
              { path: "reset/:resetId", component: ResetPasswordComponent },
              { path: "set/:resetId", component: SetPasswordComponent },
              { path: "expired/:resetId", component: ExpiredPasswordComponent }
            ]
          }
        ]
      }])
  ],
  exports: [
    RouterModule
  ]
})

export class AccountRoutingModule { }
