import { Component, Input } from "@angular/core";
import { DataPager } from "@models/data-pager";

@Component({
  selector: "pagination",
  templateUrl: "./pagination.component.html"
})

export class PaginationComponent {
  @Input() displayedPages: number = 7;
  @Input() edges: number = 1;
  @Input() showPreviousLink: boolean = false;
  @Input() showNextLink: boolean = false;
  @Input() pager: DataPager;
  @Input() pagerSizeOptions: Array<number> = [5, 10, 25, 50];
  @Input() showPageSizeOptions: boolean = false;
  @Input() recordName: string = "records";
  @Input() showDisplayingRecords: boolean = true;

  get _showPageSizeOptions() {
    return this.showPageSizeOptions && this.pager.totalResults > this.pagerSizeOptions[0];
  }

  public test = [1, 2, 3, 4, 5];

  public get test2() {
    let pages = [];
    for (let i = 1; i < 5; i++) {
      pages.push(i);
    }
    return pages;
  }

  protected get filteredPagerSizeOptions() {
    let all = false;
    return this.pagerSizeOptions.filter(o => {
      if (o < this.pager.totalResults) {
        return true;
      }
      // return first pageSize that id larger than the number of results
      if (!all) {
        all = true;
        return true;
      }
      return false;
    });
  }

  private get _interval() {
    let currentPage = this.pager.page;
    let halfWay = this.displayedPages / 2;
    let pageCount = this.pager.pageCount;
    return {
      start: Math.ceil(currentPage > halfWay ? Math.max(Math.min(currentPage - halfWay, (pageCount - this.displayedPages)), 1) : 1),
      end: Math.ceil(currentPage > halfWay ? Math.min(currentPage + halfWay, pageCount) : Math.min(this.displayedPages, pageCount))
    };
  }

  public get _pageObjects() {
    let pages = [];

    if (this.pager == null) {
      console.warn("There is no pager");
      return pages;
    }

    if (this.pager.pageSize == 0) {
      this.pager.pageSize = this.pagerSizeOptions[0];
    }

    let interval = this._interval;
    let start = 1;
    let i = start;
    let currentPage = this.pager.page;
    let pageCount = this.pager.pageCount;

    var append = (pageIndex: number, text?: string) => {

      var item, options;

      pageIndex = pageIndex < 1 ? 1 : (pageIndex < pageCount ? pageIndex : pageCount);
      //var t = text == null ? pageIndex : text;

      pages.push(text == null ? pageIndex : -1);

      //Had problems with click events not being registered on anchors when returing objects so 
      //returning numbers now & that works
      //pages.push({ page: pageIndex, text: t });
    }



    // Generate start edges
    if (interval.start > start && this.edges > start) {

      var end = Math.min(this.edges, interval.start);

      for (i = start; i < end; i++) {
        append(i);
      }

      if (this.edges < interval.start && (interval.start - this.edges != 1)) {
        append(-1, "...");
      } else if (interval.start - this.edges == 1) {
        append(this.edges);
      }
    }

    // Generate interval links
    for (i = interval.start; i <= interval.end; i++) {
      append(i);
    }

    // Generate end edges
    if (interval.end <= pageCount && this.edges > start) {

      if (pageCount - this.edges > interval.end && (pageCount - this.edges - interval.end != 1)) {
        append(-1, "...");
      } else if (pageCount - this.edges - interval.end == 1) {
        append(interval.end++);
      }

      var begin = Math.max(pageCount - this.edges, interval.end);

      for (i = begin; i <= pageCount; i++) {
        append(i);
      }
    }

    return pages;
  }

  get hasPages() {
    return this.pager.pageCount > 0;
  }

  get hasMoreThanOnePage() {
    return this._pageObjects.length > 1; //this.pager.pageCount > 1;
  }

  setPage(page) {
    this.pager.setPage(page);
  }

  alertPage(page) {
    UIkit.modal.alert(page);
  }

  previousPage() {
    this.pager.previousPage();
  }

  nextPage() {
    this.pager.nextPage();
  }

  isCurrentPage(page) {
    return page == this.pager.page;
  }

  isCurrentPageOld(page) {
    if (page == null) {
      return false;
    }
    //return (page == null || this.pager == null || this.pager.page == null) ? null :
    return page.page == this.pager.page;
  }
}
