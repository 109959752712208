import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";
import { DateUtils } from "@utils/date-utils"

@Component({
  selector: "dropdown-with-groups",
  template: `<select [id]="nameOrId" [(ngModel)]="value" [name]="nameOrId" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" [class]="cssClass">
            <optgroup *ngFor="let group of groups" [label]="group.name">
              <option *ngFor="let v of group.values" [ngValue]="_getValue(v)">{{_getValueText(v)}}</option>
            </optgroup>
        </select>`
})

export class DropdownWithGroupsComponent {
  _fieldId: string;

  @Input() cssClass: string;
  @Input() name: string;
  @Input() value: any;
  @Input() groups;
  @Input() valuetype: string = "int";
  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() showBlankFirstOption: boolean = true;
  @Input() tooltip: string;
  @Input() callback;

  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  _getValueText(value) {
    switch (this.valuetype) {
      case "simple.string":
        return value;

      case "object.date":
        var date = value;
        try {
          if (!(date instanceof Date)) {
            date = DateUtils.parseJsonDate(value);
          }
          if (date != null && (date instanceof Date)) {
            return DateUtils.formattedDateString(date);
          }
          return DateUtils.formattedDateString(date);
        } catch (ex) {
          console.error("Error parsing " + date);
          return value;
        }

      default:
        return value.name;
    }
  }

  _getValue(value) {
    switch (this.valuetype) {
      case "simple.string":
        return value;

      case "object.date":
        return <Date>value;

      default:
        return value.id;
    }
  }

  onChange(newValue) {
    let isNull = newValue == null;
    let isTrue = !isNull && newValue === true;
    switch (this.valuetype) {
      case "int":
        this.value = parseInt(newValue);
        break;

      case "bool":
        this.value = isTrue;
        break;

      case "bool?":
        this.value = isNull ? null : isTrue;
        break;

      default:
        this.value = isNull ? null : newValue;
        break;
    }

    if (this.callback != null) {
      this.callback();
    }

    this.valueChange.emit(this.value);
  }

  get nameOrId() {
    return this.name == null ? this._fieldId : this.name;
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}
