import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AccountService } from "@services/account.service";
import { AppRoutes } from "app/app-routes";

@Component({
  selector: "app-account-password-set-or-change",
  templateUrl: "./set-or-change-password.component.html"
})

export class SetOrChangePasswordComponent {
  password: string;
  confirmPassword: string;
  _show: boolean = true;
  _isInvalid: boolean = false;
  private _resetId: string;
  passwordRules: string;

  @Input() mode: string;

  get buttonText() {
    return this.mode = "reset" ? "Change password" : "Set password";
  }

  constructor(private _accountService: AccountService, private _router: Router, activatedRoute: ActivatedRoute) {

    var self = this;

    activatedRoute.params.subscribe(params => {
      self._resetId = params["resetId"];
      self._accountService.resetPasswordCheck(this._resetId, result => {
        self._isInvalid = !result;
        self._show = true;

        self._accountService.getPasswordRules(result => {
          self.passwordRules = result.join("<br/>");
        });
      });
    });
  }

  isNullOrEmpty(text: string) {
    return text == null || text.length == 0;
  }

  click(button: any) {
    button.stopSpinning();

    if (this.isNullOrEmpty(this.password) || this.isNullOrEmpty(this.confirmPassword)) {
      return;
    }

    if (this.password != this.confirmPassword) {
      UIkit.modal.alert("Passwords do not match");
      return;
    }

    var mode = this.mode;
    var callback = (result) => {
      UIkit.modal.alert("Password " + mode + "...");
    };

    //Validation done when changing password so separate validation is unnecessary

    var request = {
      resetId: this._resetId,
      newPassword: this.password
    };

    var self = this;

    this._accountService.changePassword(request, result => {
      if (result.isSuccessfull) {
        var action = self.mode == "reset" ? "changed" : "set";
        UIkit.modal.alert("Password " + action + ". Please sign in.");
        self._router.navigateByUrl(AppRoutes.Login);
      } else {
        UIkit.modal.alert(result.errorMessage);
      }
    });
  }
}
