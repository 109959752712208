import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { FooterComponent } from "./footer.component";
import { AlertMessageComponent } from "./alert-message.component";
import { NavBarComponent } from "./navbar.component";

import { FocusMeDirective } from "./form/focus-me.directive"
import { ButtonWithSpinnerComponent } from "./button-with-spinner.component";

import { CurrencyPipe } from "./pipes/currency.pipe";
import { ParseDatePipe } from "./pipes/parse-date.pipe";
import { PercentagePipe } from "./pipes/percentage.pipe";
import { TitleCasePipe } from "./pipes/title-case.pipe";
import { YesOrNoPipe } from "./pipes/yes-or-no.pipe";

import { TabsComponent } from "./tabs.component";
import { TabComponent } from "./tab.component";
import { UiLoaderComponent } from "./ui-loader.component";
import { PaginationComponent } from "./pagination.component";
import { FormattedDateComponent } from "./formatted-date.component";

import { BackButtonComponent } from "./back-button.component";

import { BlackBoxActionsButtonComponent } from "./blackbox-actions-button.component";
import { BlackBoxNamePipe } from "./pipes/blackbox-name.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
  ],
  declarations: [
    BlackBoxActionsButtonComponent,
    BlackBoxNamePipe,

    AlertMessageComponent,
    ButtonWithSpinnerComponent,
    FocusMeDirective,
    NavBarComponent,
    FooterComponent,
    PaginationComponent,
    UiLoaderComponent,
    TabComponent,
    TabsComponent,
    FormattedDateComponent,
    BackButtonComponent,
    CurrencyPipe,
    ParseDatePipe,
    PercentagePipe,
    TitleCasePipe,
    YesOrNoPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,

    AlertMessageComponent,
    ButtonWithSpinnerComponent,
    FocusMeDirective,
    NavBarComponent,
    FooterComponent,
    PaginationComponent,
    UiLoaderComponent,
    TabComponent,
    TabsComponent,
    FormattedDateComponent,
    BackButtonComponent,
    CurrencyPipe,
    ParseDatePipe,
    PercentagePipe,
    TitleCasePipe,
    YesOrNoPipe,

    BlackBoxActionsButtonComponent,
    BlackBoxNamePipe
  ]
})

export class SharedModule { }
