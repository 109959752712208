import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";
import { DateUtils } from "@utils/date-utils";

@Component({
  selector: "label-and-datepicker",
  template: `
    <div [ngClass]="{ 'field-required': isrequired }">
        <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
        <input [id]="_fieldId" 
            [type]="type" 
            [(ngModel)]="textValue" 
            (ngModelChange)="onChange($event)" 
            [ngModelOptions]="{standalone: true}"
            (input)="onEvent($event)" 
            (blur)="onBlur($event)" 
            (mouseenter)="onEvent($event)" 
            (mouseleave)="onEvent($event)" 
            data-uk-datepicker="{format:'DD/MM/YYYY'}" />
    </div>`
})

export class LabelAndDatePickerComponent {
  _fieldId: string;

  @Input() value: string; //ISO Date Format

  @Input() title: string
  @Input() isrequired: boolean = false;
  @Input() tooltip: string
  @Input() type: string = "text";

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  _initialized: boolean;

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }

  private _getInput() {
    var input = document.getElementById(this._fieldId);
    if (input == null) {
      return;
    }
    return <HTMLInputElement>input;
  }

  private padNum = num => num < 10 ? "0" + num : num.toString();

  get textValue(): string {
    var date = DateUtils.parseJsonDate(this.value);
    if (date == null) {
      return null;
    }

    return DateUtils.formattedDateString(date);
  }

  set textValue(value: string) {
    console.log(value);
  }

  onChange(newValue) {
    this.textValue = newValue;
    this.value = this.parseDate(newValue);
    this.valueChange.emit(this.value);
  }

  onBlur(event) {
    var input = this._getInput();
    setTimeout(() => {
      this.onEvent(event);
    }, 500);
  }

  parseDate(value: string) {
    if (value == null || value.length < 8) {
      return null;
    }
    var date = DateUtils.parseFormattedDate(value);
    if (date == null) {
      return null;
    }

    return DateUtils.unspecifiedDateString(date);
  }

  onEvent(event) {
    var input = this._getInput();
    if (input == null) {
      return;
    }

    if (input.value != this.textValue) {
      this.onChange(input.value);
    }
  }
}
