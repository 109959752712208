import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LoggedInComponent } from "./logged-in.component";
import { DefaultLoggedInComponent } from "./logged-in-default.component";
import { HelpIndexComponent } from "app/logged-in/help/help-index.component";
//import { AccessDeniedComponent } from "../shared/access-denied.component";
//import { Error404Component } from "../shared/error-404.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: LoggedInComponent,
        children: [
          { path: "default", component: DefaultLoggedInComponent },
          //{ path: "access-denied", component: AccessDeniedComponent },
          //{ path: "error-404", component: Error404Component },
          { path: "logs", loadChildren: () => import("./logs/logs.module").then(m => m.LogsModule) },
          { path: "management", loadChildren: () => import("./management/management.module").then(m => m.ManagementModule) },
          { path: "profile", loadChildren: () => import("./profile/profile.module").then(m => m.ProfileModule) },
          { path: "tools", loadChildren: () => import("./tools/tools.module").then(m => m.ToolsModule) },
          { path: "admin", loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule) },
          { path: "help", component: HelpIndexComponent }
        ]
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class LoggedInRoutingModule { }
