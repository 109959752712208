import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { LoginRequest } from "@models/login-request";
import { AccountService } from "../services/account.service";
import { SecurityCache } from "@models/security-cache";
import { AppRoutes } from "../app-routes";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html"
})

export class LogInComponent {
  @Input() successRoute: string = AppRoutes.LoggedInDefault;
  public loginRequest = new LoginRequest();
  public loginResult;
  isChrome: boolean;
  private _storageKey: string = SecurityCache.userNameKey;
  private _tokenKey: string = SecurityCache.userTokenKey;
  isLoggingIn: boolean = false;

  constructor(
    private _router: Router,
    private _service: AccountService) {

    let storedUsername = localStorage.getItem(this._storageKey);
    if (storedUsername != null) {
      this.loginRequest.username = storedUsername;
      this.loginRequest.rememberMe = true;
    }

    try {
      let navigator = window.navigator;
      this.isChrome = (navigator.vendor != null && navigator.vendor.indexOf("Google") > -1) ||
        (navigator.userAgent != null && navigator.userAgent.indexOf("CriOS") > -1);

      this.isChrome = true; //TODO: Remove

      let details = {
        vendor: navigator.vendor,
        userAgent: navigator.userAgent,
        screen: {
          width: screen.width,
          height: screen.height
        }
      };
      this.loginRequest.userDetails = JSON.stringify(details);
    } catch (ex) {

    }

    let storedToken = localStorage.getItem(this._tokenKey);
    if (storedToken != null) {
      this.isLoggingIn = true;
      this.loginRequest.token = storedToken;
      let successRoute = this.successRoute;
      let router = this._router;

      this._service.login(this.loginRequest, result => {
        if (result.user != null) {
          router.navigateByUrl(successRoute);
        }
        this.isLoggingIn = false;
      });
    }
  }

  login(button: any) {
    let btn = button;

    if (this.loginRequest.rememberMe) {
      localStorage.setItem(this._storageKey, this.loginRequest.username);
    }

    let fields = [this.loginRequest.username, this.loginRequest.password];
    for (let ix in fields) {
      let value = fields[ix];
      if (value == null || (value != null && value.length == 0)) {
        UIkit.modal.alert("Please enter your username and password");
        btn.stopSpinning();
        return;
      }
    }

    let successRoute = this.successRoute;
    let router = this._router;
    let blankGuid = "00000000-0000-0000-0000-000000000000";

    this._service.login(this.loginRequest, result => {
      if (result.user != null) {
        if (result.token != null && result.token !== blankGuid) {
          localStorage.setItem(this._tokenKey, result.token);
        }
        router.navigateByUrl(successRoute);
      } else {
        btn.stopSpinning();

        if (result.resetPasswordId !== blankGuid) {
          router.navigateByUrl("/account/password/expired/" + result.resetPasswordId);
          return;
        }

        UIkit.modal.alert("Login failed");
      }
    }).catch(reason => {
      btn.stopSpinning();
      UIkit.modal.alert("There has been an error logging in");
    });;
  };

  forgotMyPassword() {
    this._router.navigateByUrl("/account/forgottenpassword");
  }
}
