import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "cancel-filter",
  template: `<i *ngIf="value != null" class="uk-icon-times" (click)="cancel()" style="cursor: pointer"></i>`
})

export class CancelFilterComponent {

  @Input() value: any;
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  cancel() {
    this.value = null;
    this.valueChange.emit(this.value);
    if (this.callback != null) {
      this.callback.emit(this.value);
    }
  }

}