import { Component, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SitemapNode, BlackBoxSitemap } from "./sitemap";
import { AppConfig } from "@models/app-config";
import { SecurityCache } from "@models/security-cache";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html"
})

export class NavBarComponent {
  @Input() user;
  sitemap;
  private _storageKey: string = SecurityCache.userNameKey;
  private _tokenKey: string = SecurityCache.userTokenKey;
  private _sitemap: Array<SitemapNode>;

  constructor(
    private _securityCache: SecurityCache,
    private _config: AppConfig) {
    //TODO: validate user permissions on route change?
  }

  //_hasRole(role: string) {
  //  return this.user.roles.find(r => r.name == role) != null;
  //}

  get parentNodes() {
    if (this._sitemap == null && this.user != null) {
      this._sitemap = new BlackBoxSitemap(this._config.environment).Sitemap.filter(this._securityCache);
    }

    return this._sitemap;
  }

  getRouterLink(child) {
    return [child.url];
  }

  getIcon(child) {
    return child.icon == null ? "" : "uk-icon-" + child.icon;
  }

  performAction(child) {
    //switch (child.action) {
    //}
  }

  investigate(link) {
    if (link == null) {
      console.debug("NULL");
    } else {
      console.debug(link);
    }
    return "";
  }
}
