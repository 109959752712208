import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-checkbox-list-array",
  template: `
    <form-row [extraCss]="'checkboxlist-row'">
        <label>{{title}}</label>
        <ul class="checkboxlist">
            <ng-content></ng-content>
            <ng-template ngFor let-value [ngForOf]="filteredItems">
                <li>
                    <input [id]="checkboxId(value)" 
                           type="checkbox" 
                           [(ngModel)]="value['checked']" 
                           (ngModelChange)="onChange(value.id)" 
                           [ngModelOptions]="{standalone: true}"
                           [disabled]="isDisabled" />
                    <label [attr.for]="checkboxId(value)">{{value.name}}</label>
                </li>
            </ng-template>
        </ul>
    </form-row>`
})

export class LabelAndCheckboxListArrayComponent {
  _fieldId: string;

  private _value: Array<number>;
  @Input() set value(val: Array<number>) {
    this._value = val;

    if (val == null || this.values == null) {
      return;
    }

    this.values.filter(item => this._value.indexOf(item.id) > -1).forEach(item => item["checked"] = true);
  }

  get value() {
    return this._value == null ? [] : this._value;
  }

  @Input() filter: string;

  _values: Array<any>;
  @Input() set values(values: Array<any>) {
    this._values = values;
    if (this.value != null) {
      this.value = this.value;
    }
  }

  get values() {
    return this._values;
  }

  @Input() title: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() tooltip: string;

  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  get filteredItems() {
    if (this.filter == null || this.filter.length == 0) {
      return this.values;
    }

    let selectedItems = [];
    let items = [];

    let needle = this.filter.toLowerCase();

    for (let item of this.values) {
      if (item.checked === true) {
        selectedItems.push(item);
        continue;
      }

      if (item.name.toLowerCase().indexOf(needle) > -1) {
        items.push(item);
      }
    }

    return selectedItems.concat(items);
  }

  checkboxId(item) {
    return this._fieldId + "_" + item.id;
  }

  onChange(value) {
    if (this._value == null) {
      this._value = [];
    }

    var existing = this._value.filter(item => item == value); //find?
    if (existing.length == 0) {
      this._value.push(value);
    }

    this.valueChange.emit(this.value);
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}