import { Component, Input } from "@angular/core";

@Component({
  selector: "form-row",
  template: `
<div [class]="_css" [class.field-required]="isrequired">
    <ng-content></ng-content>
    <span class="required" *ngIf=isrequired>(Required)</span>
</div>`
})

export class FormRowComponent {
  @Input() isrequired: boolean = false;
  @Input() extraCss: string;

  get _css() {
    let css = "uk-form-row";
    css += this.extraCss != null ? " " + this.extraCss : "";
    return css;
  }

}