import { Component, Input } from "@angular/core";
import { DateUtils } from "../utils/date-utils";

@Component({
  selector: 'formatted-date',
  template: `{{formattedDate}}`
})

export class FormattedDateComponent {

  @Input() date;
  @Input() showTime: boolean = false;

  get formattedDate() {
    var parsedDate = DateUtils.parseJsonDate(this.date);

    return parsedDate == null ?
      this.date :
      this.showTime ?
        DateUtils.formattedDateTimeString(parsedDate) :
        DateUtils.formattedDateString(parsedDate);
  }
}
