export class DateUtils {
  static isoDateFormatZ = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;
  static isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
  static justIsoDateFormat = /^(\d{4})-(\d{2})-(\d{2})$/;
  static microsoftDateFormat = /^\/Date\((d|-|.*)\)[\/|\\]$/;

  public static months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  public static padNum(num) {
    return num < 10 ? "0" + num : num.toString();
  }

  public static removeTime(datetime: Date): Date {
    datetime.setHours(0);
    datetime.setMinutes(0);
    datetime.setSeconds(0);
    datetime.setMilliseconds(0);
    return datetime;
  }

  public static parseFormattedDate(value: string) {
    if (value == null || value.length < 8) {
      return null;
    }
    var parts = value.split("/");
    var day = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    var year = parseInt(parts[2]);
    if (parts[2].length == 2) {
      year += (year > 50) ? 1900 : 2000;
    }
    return new Date(year, month - 1, day, 0, 0, 0);
  }

  public static parseJsonDate(value): Date {
    var a = this.isoDateFormatZ.exec(value);
    if (a) {
      return new Date(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]); //new Date(Date.UTC(
    }

    a = this.isoDateFormat.exec(value);
    if (a) {
      return new Date(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]);
    }

    a = this.justIsoDateFormat.exec(value);
    if (a) {
      return new Date(+a[1], +a[2] - 1, +a[3], 0, 0, 0);
    }

    a = this.microsoftDateFormat.exec(value);
    if (a) {
      var b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
    return value;
  }

  public static addDays(date: Date, days: number) {
    var dt = new Date(date);
    dt.setDate(dt.getDate() + days);
    return dt;
  }

  public static addMonths(date: Date, months: number) {
    var dt = new Date(date);
    dt.setMonth(dt.getMonth() + months);
    return dt;
  }

  public static unspecifiedDateString(date: Date) {
    return DateUtils.isDate(date) ? this.yearMonthDayDateString(date) + "T" + this.formattedTimeString(date) + ":00" : ""; //.000Z";
  }

  public static yearMonthDayDateString(date: Date, separator: string = "-") {

    return DateUtils.isDate(date) ? [date.getFullYear(), this.padNum(date.getMonth() + 1), this.padNum(date.getDate())].join(separator) : "";
  }

  public static formattedDateString(date: Date) {
    return DateUtils.isDate(date) ? [this.padNum(date.getDate()), this.padNum(date.getMonth() + 1), this.padNum(date.getFullYear())].join("/") : "";
  }

  public static formattedTimeString(date: Date) {
    return DateUtils.isDate(date) ? this.padNum(date.getHours()) + ":" + this.padNum(date.getMinutes()) : "";
  }

  public static formattedDateTimeString(date: Date) {
    return DateUtils.isDate(date) ? this.formattedDateString(date) + " " + this.formattedTimeString(date) : "";
  }

  public static isDate(date) {
    let _isDate = date instanceof Date;
    return _isDate;
  }
}
