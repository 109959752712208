import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DefaultViewComponent } from "./views/defaultview.component";
import { ResetComponent } from "./views/reset.component";


@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: "", component: DefaultViewComponent },
      { path: "reset", component: ResetComponent },
      { path: "account", loadChildren: () => import("app/account/account.module").then(m => m.AccountModule) },
      { path: "logged-in", loadChildren: () => import("app/logged-in/logged-in.module").then(m => m.LoggedInModule) }
    ], { enableTracing: false })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
