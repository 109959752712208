/// <reference path="../../node_modules/@angular/core/core.d.ts" />
/// <reference path="../../node_modules/@types/uikit/index.d.ts" />
import { Component } from "@angular/core";
import { AccountService } from "./services/account.service";

@Component({
  selector: 'app-root',
  template: "<router-outlet></router-outlet>"
})

export class AppComponent {
  constructor(accountService: AccountService) {
    accountService.isLoggedIn(() => { });
  }
}
