import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "percentage" })
export class PercentagePipe implements PipeTransform {
  transform(value: number): string {
    return value == null ?
      "" :
      ( value * 100 ).toLocaleString(undefined, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";
  }
}
