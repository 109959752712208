import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
//import { FormInputsModule } from "../shared/form/form-inputs.module";

import { AccountRoutingModule } from "./account-routing.module";

import { AccountComponent } from "./account.component";
import { LogInComponent } from "./login.component";
import { LogOutComponent } from "./logout.component";
import { SetOrChangePasswordComponent } from "./password/set-or-change-password.component";
import { ForgottenPasswordComponent } from "./password/forgotten-password.component";
import { SetPasswordComponent } from "./password/set-password.component";
import { ResetPasswordComponent } from "./password/reset-password.component";
import { ExpiredPasswordComponent } from "./password/expired-password.component";

@NgModule({
  imports: [
    AccountRoutingModule,
    SharedModule
    //FormInputsModule
  ],
  declarations: [
    AccountComponent,
    LogInComponent,
    LogOutComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    SetOrChangePasswordComponent,
    SetPasswordComponent,
    ExpiredPasswordComponent
  ]
})

export class AccountModule { }
