import { Component, Input } from "@angular/core";

@Component({
  selector: "alert-message",
  template: `
<p [class]="_css" *ngIf="model == null || model.length == 0" [attr.data-uk-alert]="canClose">
    <a *ngIf="canClose" href="" class="uk-alert-close uk-close"></a>
    <ng-content></ng-content>
</p>`
})

export class AlertMessageComponent {
  @Input() model: Array<Object>;
  @Input("extra-css") extraCss: string;
  @Input() type: string;
  @Input() width: string;
  @Input("is-centered") isCentered: boolean = false;
  @Input() canClose: boolean = false;

  //TODO: Size, Positioning
  private get _css(): string {
    let css = ["uk-alert"];
    if (this.type != null) {
      css.push("uk-alert-" + this.type);
    }
    if (this.width != null) {
      css.push("uk-width-" + this.width);
    }
    if (this.isCentered) {
      css.push("uk-container-center");
    }
    if (this.extraCss != null) {
      css.push(this.extraCss);
    }

    return css.join(" ");
  }
}