import { Injectable } from "@angular/core";

@Injectable()
export class WindowWrapper extends Window {
  $: any;
}

@Injectable()
export class DocumentWrapper extends Document {
  $: any;
}

@Injectable()
export class ScreenWrapper extends Screen {
  $: any;
}
