import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "currency" })
export class CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    //Note: { style: 'currency', currency: "ZAR" }) returns ZAR rather than R
    //Angular comes with a stock CurrencyPipe
    if (value != null && typeof (value) == "string") {
      value = parseInt(value);
    }

    return "R" + (value == null ?
      "-" :
      value.toLocaleString(undefined, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }));
  }
}