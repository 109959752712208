import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "../app-routes";
import { AccountService } from "../services/account.service";
import { SecurityCache } from "@models/security-cache";

@Component({
  selector: "app-logged-in-default",
  template: ""
})

export class DefaultLoggedInComponent implements OnInit {
  public user;

  constructor(
    private _accountService: AccountService,
    private _cache: SecurityCache,
    private _router: Router) {
  }

  ngOnInit() {
    var self = this;

    let defaultRoute = "/logged-in/management";

    if (this._cache.loggedInUser != null) {
      this.user = this._cache.loggedInUser;
      self._router.navigateByUrl(defaultRoute);
      return;
    }

    this._accountService.isLoggedIn(() => {
      self._router.navigateByUrl(defaultRoute);
    }, () => {
      self._router.navigateByUrl(AppRoutes.Login);
    });
  }
}
