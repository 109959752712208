import { Component, ContentChildren, QueryList, AfterContentInit, Input, Output, EventEmitter  } from "@angular/core";
import { TabComponent } from "./tab.component";

@Component({
  selector: 'tabs',
  template: `<ul class="uk-tab" data-uk-tab>
      <li *ngFor="let tab of enabledTabs; index as idx" (click)="selectTab(tab, idx)" [class.uk-active]="tab.isActive" [class.uk-disabled]="tab.isDisabled">
        <a [innerHTML]="tab.titleWithCount"></a>
      </li>
    </ul>
    <ng-content></ng-content>
  `
})
export class TabsComponent implements AfterContentInit {

  private _isInitialized: boolean = false;
  private _initialIndex: number;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  private _activeTabIndex: number;
  @Input() set activeTabIndex(index: number) {
    if (index == null) {
      return;
    }
    if (!this._isInitialized) {
      this._initialIndex = index;
      return;
    }
    if (index == this.activeTabIndex) {
      return;
    }
    this.selectTabIndex(index);

    this._setIndex(index);
  }

  get activeTabIndex(): number {
    return this._activeTabIndex;
  }

  @Output() activeTabIndexChange: EventEmitter<number> = new EventEmitter<number>();

  private _setIndex(index: number) {
    if (!this._isInitialized) {
      this._initialIndex = index;
      return;
    }

    this._activeTabIndex = index;

    if (this._isInitialized) {
      this.activeTabIndexChange.emit(index);
    }
  }

  get enabledTabs(): TabComponent[] {
    return this.tabs.filter(tab => tab.isEnabled);
  }

  ngAfterContentInit() {
    let activeTabs = this.enabledTabs.filter((tab) => tab.isActive);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, 0);
    }

    this._isInitialized = true;

    if (this._initialIndex != null) {
      this._setIndex(this._initialIndex);
    }
  }

  public selectTabIndex(index: number) {
    let self = this;
    if (this.tabs == null) {
      return;
    }
    try {
      var tabs = self.tabs.toArray();

      tabs.forEach(t => t.isActive = false);

      tabs[index].select();
    } catch (ex) {}
  }

  public getTab(index: number) {
    if (this.tabs == null) {
      return;
    }

    var tabs = this.tabs.toArray();
    return tabs[index];
  }

  selectTab(tab: TabComponent, index: number) {
    if (this.tabs == null || tab == null || tab.isDisabled) {
      return;
    }

    // deactivate all tabs
    this.tabs.toArray().forEach(t => t.isActive = false);

    if (tab == null) {
      return;
    }
    // activate the tab the user has clicked on.
    tab.select();

    this._setIndex(index);
  }
}
