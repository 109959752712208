import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "label-and-required-dropdown",
  template: `
    <label-and-dropdown 
        [(value)]="value" 
        [values]="values" 
        [valuetype]="valuetype"
        [isrequired]="true"
        [title]="title"
        [tooltip]="tooltip"
        (valueChange)="onChange($event)"></label-and-dropdown>`
})

export class LabelAndRequiredDropdownComponent {
  @Input() value;
  @Input() values;
  @Input() valuetype: string = "int";
  @Input() title: string;
  @Input() tooltip: string;

  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }
}