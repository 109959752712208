import { Injectable } from "@angular/core";

@Injectable()
export class Cache {
  blackBoxes: Array<any>;

  clear() {
    if (this.blackBoxes != null) {
      this.blackBoxes.length = 0;
    }
  }
}
