import { Input } from "@angular/core";


// object & page class? Assign objects to pages

export class DataPager {
  @Input() pageSize: number = 20;
  @Input() page: number = 0;
  pageCount: number = 0;
  pagedResults: Array<any>;
  pages: Array<number>;

  constructor(pageSize?: number) {
    if (pageSize != null && pageSize > 0) {
      this.pageSize = pageSize;
    }
  }

  private _results: Array<any>;

  set results(value: Array<any>) {
    this._results = value;
    this.setPage(1);
  }

  get results() {
    return this._results;
  }

  setPage(num) {
    let count = this.results.length;
    this.pages = [];
    this.pagedResults = [];

    if (count == 0) {
      this.pageCount = 0;
      return;
    }

    this.page = num;

    this.pageCount = Math.ceil(count / this.pageSize);

    let page = this.page <= 0 ? 1 : this.page;
    let startIndex = (page - 1) * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    endIndex = endIndex > count ? count : endIndex;

    for (let i = startIndex; i < endIndex; i++) {
      this.pagedResults.push(this.results[i]);
    }

    for (var i = 1; i <= this.pageCount; i++) {
      this.pages.push(i);
    }
  }

  nextPage() {
    this.setPage(this.page == this.pageCount ? 1 : (this.page + 1));
  }

  previousPage() {
    this.setPage(this.page == 1 ? this.pageCount : (this.page - 1));
  }

  get hasPreviousPage() {
    return this.page > 1;
  }

  get hasNextPage() {
    return this.page < this.pageCount;
  }

  get totalResults() {
    return this._results == null ? 0 : this._results.length;
  }

  get hasResults() {
    return this.totalResults > 0;
  }

  get recordsDisplaying() {
    let count = this.results.length;
    let page = this.page <= 0 ? 1 : this.page;
    let startIndex = (page - 1) * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    endIndex = endIndex > count ? count : endIndex;
    return {
      first: startIndex, last: endIndex
    };
  }
}