import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "search-input",
  template: `
    <div class="uk-form-search">
      <i *ngIf="value?.length > 0" class="uk-icon-times" (click)="clear()"></i>
      <input type="text" [name]="name" [id]="name" [(ngModel)]="value" (ngModelChange)="valueChange.emit($event)" [title]="title">
    </div>
`
})

export class SearchInputComponent {
  @Input() name: string;
  @Input() title: string;

  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  clear() {
    this.value = "";
    this.valueChange.emit("");
  }

}