import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestMethod } from "@angular/http";
import { DateUtils } from "./date-utils";

@Injectable()
export class HttpHelper {
  constructor(private _http: HttpClient) {
  }

  delete(url: string, headers = null, useUniqueUrl: boolean = false) {
    return this.request(RequestMethod.Delete, url, null, headers, useUniqueUrl);
  }

  get(url: string, headers = null, useUniqueUrl: boolean = false) {
    return this.request(RequestMethod.Get, url, null, headers, useUniqueUrl);
  }

  put(url: string, data, headers = null, useUniqueUrl: boolean = false) {
    return this.request(RequestMethod.Put, url, data, headers, useUniqueUrl);
  }

  post(url: string, data, headers = null, useUniqueUrl: boolean = false, stringify: boolean = true) {
    return this.request(RequestMethod.Post, url, data, headers, useUniqueUrl, stringify);
  }

  //dateToString(key, value) {
  //  if (DateUtils.isDate(value)) {
  //    return DateUtils.unspecifiedDateString(value);
  //  }

  //  return value;
  //}

  request(method: RequestMethod, url: string, data = null, headers = null, useUniqueUrl: boolean = false, stringify: boolean = true) {
    headers = headers == null ? new Headers() : headers;
    headers = headers.set("Content-Type", "application/json; charset=utf-8");

    let dateToString = (key, value) => DateUtils.isDate(value) ? DateUtils.unspecifiedDateString(value) : value;

    let options = {
      headers: headers,
      method: method,
      body: data != null ? JSON.stringify(data, dateToString) : null
    };

    if (useUniqueUrl) {
      url += "?t=" + new Date().getTime();
    }

    switch (method) {
      case RequestMethod.Get:
        return this._http.get(url, options);

      case RequestMethod.Delete:
        return this._http.delete(url, options);

      case RequestMethod.Post:
        return this._http.post(url, data, options);

      case RequestMethod.Put:
        return this._http.put(url, data, options);

    }
    return null;
  }

  deleteAndCallback(url: string, callback, headers = null, useUniqueUrl: boolean = false) {
    return this.delete(url, headers, useUniqueUrl).toPromise().then(response => callback(response));
  }

  getAndCallback(url: string, callback, headers = null, useUniqueUrl: boolean = false) {
    return this.get(url, headers, useUniqueUrl).toPromise().then(response => callback(response));
  }

  putAndCallback(url: string, data, callback, headers = null, useUniqueUrl: boolean = false) {
    return this.put(url, data, headers, useUniqueUrl).toPromise().then(response => callback(response));
  }

  postAndCallback(url: string, data, callback, headers = null, useUniqueUrl: boolean = false, stringify: boolean = true) {
    return this.post(url, data, headers, useUniqueUrl, stringify).toPromise().then(response => callback(response));
  }
}

export class HttpRequestWithCallback {
  public makeUrlUnique: boolean = false;
  public stringify: boolean = true;

  constructor(private _http: HttpHelper, public url: string, public headers?: HttpHeaders) {
  }

  public delete(callback) {
    return this._http.deleteAndCallback(this.url, callback, this.headers, this.makeUrlUnique);
  }

  public get(callback) {
    return this._http.getAndCallback(this.url, callback, this.headers, this.makeUrlUnique);
  }

  public put(data, callback) {
    return this._http.putAndCallback(this.url, data, callback, this.headers, this.makeUrlUnique);
  }

  public post(data, callback) {
    return this._http.postAndCallback(this.url, data, callback, this.headers, this.makeUrlUnique, this.stringify);
  }
}
