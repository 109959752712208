import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "../services/account.service";
import { SecurityCache } from "@models/security-cache";
import { Cache } from "@models/cache";

@Component({
  selector: "app-resetview",
  template: ""
})

export class ResetComponent implements OnInit {
  constructor(
    private _router: Router,
    private _service: AccountService,
    private _cache: Cache,
    private _securityCache: SecurityCache) {
  }

  ngOnInit() {
    this._cache.clear();

    this._securityCache.resetLoggedInUser(null);

    let self = this;
    this._service.isLoggedIn(() => {
      self._router.navigate(["./logged-in/default"]);
    }, () => {
      self._router.navigate(["./account/login"]);
    });
  }
}
