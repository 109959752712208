import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "title-case" })
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    return value == null ?
      "" :
      value
        .replace(/([A-Z]{1})([a-z]{1})/g, ' $1$2')
        .replace(/^./, str => str.toUpperCase())
        .trim();
  }
}