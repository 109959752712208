import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-dropdown",
  template: `
    <form-row [isrequired]="isrequired">
      <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
      <dropdown [name]="_fieldId"
                [(value)]="value"
                (valueChange)="valueChange.emit($event)"
                [(filter)]="filter"        
                [values]="values"
                [cssClass]="selectCss"
                [valuetype]="valuetype"
                [isrequired]="isrequired"
                [showBlankFirstOption]="showBlankFirstOption"></dropdown>
      <cancel-filter *ngIf="showCancel" [(value)]="value" (callback)="_cancel()"></cancel-filter>
    </form-row>`
})

export class LabelAndDropdownComponent {
  _fieldId: string;

  @Input() value: any;
  @Input() values;
  @Input() valuetype: string = "object.idname";
  @Input() filter: string;
  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() showBlankFirstOption: boolean = true;
  @Input() tooltip: string;
  @Input("show-cancel") showCancel: boolean = false;
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  private _css: string;
  @Input("select-css") set selectCss(value: string) {
    this._css = value;
  }
  get selectCss() {
    return this._css == null ? "" : this._css;
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  _cancel() {
    this.value = null;
    this.valueChange.emit(this.value);

    if (this.onCancel != null) {
      this.onCancel.emit();
    }
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}
