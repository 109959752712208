import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-number",
  template: `
    <form-row [isrequired]="isrequired">
      <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
      <input [id]="_fieldId"
type="number"
[step]="_step"
[attr.disabled]="isDisabled ? '' : null"
[(ngModel)]="value"
[min]="min"
[max]="max"
(keypress)="captureValue($event)"
(keyup)="validate($event)"
(ngModelChange)="onChange($event)"
[ngModelOptions]="{standalone: true}"
placeholder="{{title}}"
[class]="inputCss" />
      <ng-content></ng-content>
    </form-row>`
})

export class LabelAndNumberComponent {
  _fieldId: string;
  @Input() min: number = 0;
  @Input() max: number;
  @Input() step: number = 1;
  @Input() isDecimal: boolean;
  @Input() value: string;
  @Input() title: string;
  @Input("input-css") inputCss: string;
  @Input() isrequired: boolean = false;
  @Input() tooltip: string;
  @Input("disabled") isDisabled: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  private _value;

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  captureValue(event: KeyboardEvent) {
    this._value = event.target["value"];
  }

  validKeys = [8, 46, 13, 16, 109, 188, 190, 173]; //delete, backspace, enter, shift

  validate(event: KeyboardEvent) {

      //0 - 9 = 48 - 57
      if (event.keyCode >= 48
          && event.keyCode <= 57) {
      return;
    }

    //numpad 0 - 9 = 96 - 105
    if (event.keyCode >= 96 && event.keyCode <= 105) {
      return;
    }

    if (this.validKeys.indexOf(event.keyCode) > -1) {
      return;
    }

    //. = 110
    if (this.isDecimal && event.keyCode === 110 && this._value.indexOf(".") === -1) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.onChange(this._value);
  }

  get _step() {
    return this.isDecimal ? "any" : this.step.toString();
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }
}
