import { Permission } from "@models/permission";

export class SecurityCache {
  static userNameKey = "app:username";
  static userTokenKey = "app:usertoken";

  loginResult;
  get loggedInUser() {
    return this.loginResult != null && this.loginResult.user != null ? this.loginResult.user : null;
  }

  resetLoggedInUser(result) {
    this.loginResult = result;
  }

  private _getAreaPermissions(blackBox: string, area: string) {
    //check specific bb & global (none)
    var bbs = [blackBox.replace("/", ""), "none"];

    try {
      for (let bb of bbs) {
        let blackBoxPermissions = this.loggedInUser.permissions[bb];
        if (blackBoxPermissions != null && blackBoxPermissions[area] != null) {
          return blackBoxPermissions[area];
        }
      }
    } catch (ex) { }

    return null;
  }

  getPermissions(blackBox: string, area: string) {
    if (this.loggedInUser == null) {
      return null;
    }

    if (this.loggedInUser.permissions == null) {
      console.log("No permissions. Log out & then back in again?");
      return null;
    }

    return this._getAreaPermissions(blackBox, area);
  }

  hasPermissionRegardlessOfBlackBox(area: string, permission: number) {
    var bbs = [];
    for (let bb in this.loggedInUser.permissions) {
      bbs.push(bb);
    }
    bbs.push("none");

    for (let bb of bbs) {
      let blackBoxPermissions = this.loggedInUser.permissions[bb];
      if (blackBoxPermissions != null && blackBoxPermissions[area] != null && Permission.contains(blackBoxPermissions[area], permission)) {
        return true;
      }
    }

    return false;
  }

  hasAnyPermissions(blackBox: string, area: string) {
    let blackBoxPermissions = this.getPermissions(blackBox, area);

    let permissions = [Permission.create, Permission.read, Permission.update, Permission.delete];

    for (let permission of permissions) {
      if (Permission.contains(blackBoxPermissions, permission)) {
        return true;
      }
    }

    return false;
  }

  hasPermission(blackBox: string, area: string, permission: number) {
    let blackBoxPermissions = this.getPermissions(blackBox, area);

    if (blackBoxPermissions == null) {
      return false;
    }

    return Permission.contains(blackBoxPermissions, permission);
  }
}
