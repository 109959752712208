import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

declare let __webpack_public_path__: any;
__webpack_public_path__ = "/dist/";
//need to define it this way as initialisers are not allowed in ambient contexts

if (environment.isProduction) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch(err => console.log(err));
