import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "button-with-spinner",
  template: `
<button [disabled]="isSpinning || isDisabled" [class]="_buttonCss" (click)="click()">
    <i [class]="_iconCss"></i>
    {{text}}
</button>
`
})

export class ButtonWithSpinnerComponent {
  @Input() icon: string;
  @Input() spinnerIcon: string = "spinner";
  @Input() css: string;
  @Input() text: string;
  @Input() isSpinning: boolean = false;
  @Input("disabled") isDisabled: boolean = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  get _buttonCss() {
    return "uk-button " + this.css;
  }

  get _spinningCss() {
    return this._icon(this.spinnerIcon) + " " + this._icon("spin");
  }

  private _icon(icon: string) {
    return "uk-icon-" + icon;
  }

  get _iconCss() {
    return this.isSpinning ? this._spinningCss : this._icon(this.icon);
  }

  stopSpinning() {
    this.isSpinning = false;
  }

  startSpinning() {
    this.isSpinning = true;
  }

  click() {
    this.isSpinning = true;
    var self = this;
    this.onClick.emit(self);
  }
}