/// <reference path="../app-routes.ts" />
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { LoginRequest } from "@models/login-request";
import { AccountService } from "../services/account.service";
import { ButtonWithSpinnerComponent } from "../shared/button-with-spinner.component";
import { SecurityCache } from "@models/security-cache";
import { AppRoutes } from "../app-routes";

@Component({
  selector: "app-simple-login",
  templateUrl: "./simple-login-form.component.html"
})

export class SimpleLogInFormComponent {
  public loginRequest = new LoginRequest();
  public loginResult;
  private _storageKey: string = SecurityCache.userNameKey;
  private _tokenKey: string = SecurityCache.userTokenKey;
  isLoggingIn: boolean = false;
  @Input("show-login-button") showLoginButton: boolean = true;
  @Input() successRoute: string = AppRoutes.LoggedInDefault;

  @Output() onLogin: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _router: Router,
    private _accountService: AccountService) {
    let storedUsername = localStorage.getItem(this._storageKey);
    if (storedUsername != null) {
      this.loginRequest.username = storedUsername;
      this.loginRequest.rememberMe = true;
    }

    let storedToken = localStorage.getItem(this._tokenKey);
    if (storedToken != null) {
      this.isLoggingIn = true;
      this.loginRequest.token = storedToken;
      //var successRoute = this.successRoute;
      //var router = this._router;

      this._accountService.login(this.loginRequest, result => {
        if (result.user != null) {
          this.onLogin.emit(true);
        }
      });
    }
  }

  login(button: ButtonWithSpinnerComponent) {

    if (this.loginRequest.rememberMe) {
      localStorage.setItem(this._storageKey, this.loginRequest.username);
    }

    let fields = [this.loginRequest.username, this.loginRequest.password];
    for (let ix in fields) {
      var value = fields[ix];
      if (value == null || (value != null && value.length == 0)) {
        UIkit.modal.alert("Please enter your username and password");
        if (button != null) {
          button.stopSpinning();
        }
        return;
      }
    }

    this._accountService.login(this.loginRequest, result => {
      if (result.user != null) {
        if (result.token != null && result.token != "00000000-0000-0000-0000-000000000000") {
          localStorage.setItem(this._tokenKey, result.token);
        }
        this.onLogin.emit(true);
      } else {
        if (button != null) {
          button.stopSpinning();
        }
        UIkit.modal.alert("Login failed");
        this.onLogin.emit(false);
      }
    });
  };
}
