import { FilterOperator } from "./filter-operator"

export class Filter {
    public static filters = {
        string: [
            FilterOperator.Contains,
            FilterOperator.DoesNotContain,
            FilterOperator.StartsWith,
            FilterOperator.EndsWith,
            FilterOperator.EqualTo,
            FilterOperator.NotEqualTo,
            FilterOperator.IsEmpty,
            FilterOperator.IsNotEmpty,
            FilterOperator.IsNull,
            FilterOperator.IsNotNull
        ],
        number: [
            FilterOperator.EqualTo,
            FilterOperator.NotEqualTo,
            FilterOperator.GreaterThan,
            FilterOperator.LessThan,
            FilterOperator.GreaterOrEqualTo,
            FilterOperator.LessThanOrEqualTo,
            FilterOperator.Between,
            FilterOperator.NotBetween
        ]
    }

    public static operatorName(operator: FilterOperator) {
        switch (operator) {
            case FilterOperator.EqualTo:
                return "equal to";

            case FilterOperator.NotEqualTo:
                return "not equal to";

            case FilterOperator.GreaterThan:
                return "greater than";

            case FilterOperator.LessThan:
                return "less than";

            case FilterOperator.GreaterOrEqualTo:
                return "greater or equal to";

            case FilterOperator.LessThanOrEqualTo:
                return "less than or equal to";

            case FilterOperator.Between:
                return "between";

            case FilterOperator.NotBetween:
                return "not between";

            case FilterOperator.Contains:
                return "contains";

            case FilterOperator.DoesNotContain:
                return "does not contain";

            case FilterOperator.StartsWith:
                return "starts with";

            case FilterOperator.EndsWith:
                return "ends with";

            case FilterOperator.IsEmpty:
                return "is empty";

            case FilterOperator.IsNotEmpty:
                return "is not empty";

            case FilterOperator.IsNull:
                return "is null";

            case FilterOperator.IsNotNull:
                return "is not null";

            default:
                return "";
        }
    }

    public static compareNumber(propertyValue: number, operator: FilterOperator, comparisonValue: number, comparisonValue2: number) {
        switch (operator) {
            case FilterOperator.EqualTo:
                return propertyValue == comparisonValue;

            case FilterOperator.NotEqualTo:
                return propertyValue != comparisonValue;

            case FilterOperator.GreaterThan:
                return propertyValue > comparisonValue;

            case FilterOperator.LessThan:
                return propertyValue < comparisonValue;

            case FilterOperator.GreaterOrEqualTo:
                return propertyValue >= comparisonValue;

            case FilterOperator.LessThanOrEqualTo:
                return propertyValue <= comparisonValue;

            case FilterOperator.Between:
                return propertyValue >= comparisonValue && propertyValue <= comparisonValue2;

            case FilterOperator.NotBetween:
                return !(propertyValue >= comparisonValue && propertyValue <= comparisonValue2);

            default:
                return false;
        }
    }

    public static compareString(propertyValue: string, operator: FilterOperator, comparisonValue: string) {
        if ((propertyValue == null && comparisonValue != null) || (propertyValue != null && comparisonValue == null)) {
            return false;
        }

        switch (operator) {
            case FilterOperator.Contains:
                return propertyValue.indexOf(comparisonValue) > -1;

            case FilterOperator.DoesNotContain:
                return propertyValue.indexOf(comparisonValue) == -1;

            case FilterOperator.StartsWith:
                return propertyValue.startsWith(comparisonValue);

            case FilterOperator.EndsWith:
                return propertyValue.endsWith(comparisonValue);

            case FilterOperator.EqualTo:
                return propertyValue == comparisonValue;

            case FilterOperator.NotEqualTo:
                return propertyValue != comparisonValue;

            case FilterOperator.IsEmpty:
                return propertyValue != null && propertyValue.length == 0;

            case FilterOperator.IsNotEmpty:
                return propertyValue != null && propertyValue.length != 0;

            case FilterOperator.IsNull:
                return propertyValue == null;

            case FilterOperator.IsNotNull:
                return propertyValue != null;

            default:
                return false;
        }   
    }
}
