import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-checkbox-list2",
  template: `
    <form-row [extraCss]="'checkboxlist-row'">
        <label>{{title}}</label>
        <ul class="checkboxlist">
            <ng-content></ng-content>
            <ng-template ngFor let-value [ngForOf]="values" let-ix="index">
                <li>
                    <input [id]="checkboxId(ix)" 
                           type="checkbox" 
                           [(ngModel)]="value.isChecked" 
                           (ngModelChange)="onChange(value)" 
                           [ngModelOptions]="{standalone: true}"
                           [disabled]="isDisabled" />
                    <label [attr.for]="checkboxId(ix)">{{getValueText(value.item)}}</label>
                </li>
            </ng-template>
        </ul>
    </form-row>`
})

export class LabelAndCheckboxListComponent2 {
  _fieldId: string;
  private _value: Array<any>;

  @Input() set value(val: Array<any>) {
    if (val == null) {
      this._value = [];
      return;
    }

    this._value = val;

    if (this.values == null || this.values.length == 0) {
      return;
    }

    for (let value of this.values) {
      if (this.values.indexOf(value) > -1) {
        value.checked = true;
      }
    }
  }

  get value() {
    return this._value;
  }

  @Input() _values: Array<any>;

  @Input() set values(vals: Array<any>) {
    if (vals == null) {
      console.warn("CheckboxList2 Vals = null");
      return;
    }
    this._values = vals.map(v => {
      var isChecked = this.value != null && this.value.indexOf(v) > -1;
      return {
        item: v,
        isChecked: isChecked
      };
    });
  }

  get values() {
    return this._values;
  }

  @Input() valuetype: string = "";
  @Input() title: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() tooltip: string;

  @Output() valueChange: EventEmitter<Object> = new EventEmitter();

  protected getValueText(value) {
    switch (this.valuetype) {
      case "simple.string":
        return value;

      case "year-and-month":
        let month = value.getMonth() + 1;
        return (month < 10 ? "0" + month : month.toString()) + "/" + value.getFullYear().toString();

      default:
        return value.name;
    }
  }

  checkboxId(index) {
    return this._fieldId + "_" + index;
  }

  onChange(value) {
    this._value = this.values.filter(item => item.isChecked).map(item => item.item);
    this.valueChange.emit(this._value);
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId();
  }
}