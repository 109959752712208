import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-native-datepicker",
  template: `
    <form-row [isrequired]="isrequired">
      <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
      <native-date-picker [name]= "_fieldId" 
            [(min)]="min"
            [(max)]="max"
            [(value)]="value" 
            (valueChange)="valueChange.emit($event)"></native-date-picker>
    </form-row>`
})

export class LabelAndNativeDatePickerComponent {
  _fieldId: string;

  @Input() value: string; //ISO Date Format
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() title: string;
  @Input() isrequired: boolean = false;
  @Input() tooltip: string;
  @Input() min: string = "text";
  @Input() max: string = "text";

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }
}