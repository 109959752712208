export enum FilterOperator {
    EqualTo,
    NotEqualTo,
    GreaterThan,
    LessThan,
    GreaterOrEqualTo,
    LessThanOrEqualTo,
    Between,
    NotBetween,
    IsNull,
    IsNotNull,
    Contains,
    DoesNotContain,
    StartsWith,
    EndsWith,
    IsEmpty,
    IsNotEmpty
}