import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'tab',
  template: `
    <div *ngIf="isEnabled" [hidden]="!isActive" class="tab">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  //Note: best to do [title]="'{title}'" rather than title="{title}" as the tab content element will get a hover over title
  @Input() title: string;
  @Input("item-count") itemCount: number;
  @Input("enabled") isEnabled = true;
  @Input("disabled") isDisabled = false;
  @Input("active") isActive = false;
  @Input("strong") isStrong = false;

  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

  public select() {
    this.isActive = true;
    this.onSelect.emit(this.title);
  }

  get titleWithCount(): string {
    let title = this.title;

    if (this.isStrong) {
      title = `<strong>${title}</strong>`;
    }

    if (this.itemCount != null) {
      title += ` (${this.itemCount})`;
    }
    return title;
  }
}