import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: '[focusMe]'
})
export class FocusMeDirective {
  @Input('focusMe') hasFocus: boolean;

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }

  ngOnChanges(changes) {
    if (changes.hasFocus && changes.hasFocus.currentValue === true) {
      this.elementRef.nativeElement.focus();
    }
  }
}