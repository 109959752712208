import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormIdService } from "@services/form-id.service";

@Component({
  selector: "label-and-input",
  template: `
    <form-row [isrequired]="isrequired">
      <label-and-tooltip [fieldid]="_fieldId" [title]="title" [tooltip]="tooltip"></label-and-tooltip>
      <input [id]="_fieldId" 
             [type]="type" 
             [class.uk-form-danger]="!isValid" 
             [maxlength]="maxlength"
             [pattern]="pattern"
             [(ngModel)]="value" 
             (ngModelChange)="onChange($event)" 
             [ngModelOptions]="{standalone: true}" 
             placeholder="{{title}}" 
             [class]="inputCss"
             [attr.disabled]="isDisabled ? '' : null"
             [auto-complete]="autocomplete" />
      <ng-content></ng-content>
      <span *ngIf="isPatternError && errorIcon != null" class="pattern-error" [title]="patternErrorMessage"><i [class]="_errorIcon"></i></span>
      <span *ngIf="isPatternError && errorIcon == null" class="pattern-error">{{patternErrorMessage}}</span>
    </form-row>`
})

export class LabelAndInputComponent {
  _fieldId: string;

  _value: string;
  @Input() set value(val: string) {
    this._value = val;
    this.validate();
  }
  get value() {
    return this._value;
  }

  //Note to self: Angular requires the change events to be named <inputName>Change()
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  @Input() autocomplete: boolean = null;
  @Input() title: string;
  @Input("input-css") inputCss: string;
  @Input() isrequired: boolean = false;
  @Input() tooltip: string;
  @Input() type: string = "text";
  @Input() maxlength: number; //= 524288
  @Input("disabled") isDisabled: boolean = false;

  _pattern: string
  @Input() set pattern(val: string) {
    this._pattern = val;
    this.validate();
  }
  get pattern() {
    return this._pattern;
  }

  get _errorIcon() {
    return "uk-icon-" + (this.errorIcon == null ? this.errorIcon : "exclamation-circle");
  }

  @Input("error-icon") errorIcon: string;

  private _patternMessage: string = "Invalid format";
  @Input() set patternErrorMessage(val: string) {
    this._patternMessage = val;
  }
  get patternErrorMessage() {
    return this._patternMessage;
  }

  isPatternError: boolean;

  errorMsg: string;

  public get isValid() {
    if (this.isPatternError) {
      return false;
    }

    if (this.type == "email") {
      var input = document.getElementById(this._fieldId) as HTMLFormElement;
      if (input != null) {
        return input.checkValidity();
      }
    }

    return true;
  }


  validate() {
    if (!this.isNotNullOrEmpty(this.pattern)) {
      this.isPatternError = !this.isNotNullOrEmpty(this.value) && !new RegExp(this.pattern).test(this.value);
    }

    if (this.type == "email") {
      let input = document.getElementById(this._fieldId) as HTMLFormElement;
      if (input != null) {
        if (this._patternMessage == "Invalid format") {
          this._patternMessage = "Invalid email format";
        }
        this.isPatternError = !input.checkValidity();
      }
    }
  }

  constructor(formIdService: FormIdService) {
    this._fieldId = formIdService.getId("input");
  }

  isNotNullOrEmpty(text: string) {
    return text != null && text.length > 0;
  }
}