import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "../services/account.service";

@Component({
  selector: "app-defaultview",
  template: ""
})

export class DefaultViewComponent implements OnInit {
  constructor(
    private _router: Router,
    private _service: AccountService) {
  }

  ngOnInit() {
    let self = this;
    this._service.isLoggedIn(() => {
      self._router.navigate(["./logged-in/default"]);
    }, () => {
      self._router.navigate(["./account/login"]);
    });
  }
}
