import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "label-and-boolean",
  template: `
    <label-and-dropdown
        [(value)]="value"
        [(values)]="_values"
        [valuetype]="'bool'"
        [title]="title"
        [tooltip]="tooltip"
        [showBlankFirstOption]="false"
        (valueChange)="onChange($event)"></label-and-dropdown>`
})

export class LabelAndBooleanComponent {
  _values = [{ id: true, name: "Yes" }, { "id": false, name: "No" }];

  @Input() value: boolean;

  @Input() title: string;

  @Input() tooltip: string;

  @Output() valueChange = new EventEmitter<boolean>();

  onChange(newValue) {
    this.value = newValue;
    this.valueChange.emit(this.value);
  }
}
