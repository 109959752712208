import { Component } from "@angular/core";
import { AccountService } from "../services/account.service";

@Component({
  selector: "app-logout",
  template: `
<div class="uk-flex uk-flex-center">
  <div class="uk-alert">You have been logged out</div>
</div>`
})

export class LogOutComponent {
  constructor(service: AccountService) {
    service.logout();
  }
}
