import { HttpHeaders } from "@angular/common/http";
import { HttpHelper, HttpRequestWithCallback } from "@utils/http-helper";
import { SecurityCache } from "@models/security-cache";

export abstract class BaseApiService {
  static UserTokenKey: string = "app.userid";

  constructor(private _securityCache: SecurityCache,
    private _http: HttpHelper,
    protected apiPathPrefix: string) {
    if (apiPathPrefix != null && apiPathPrefix.length > 0 && !this.apiPathPrefix.endsWith("/")) {
      this.apiPathPrefix += "/";
    }

    this.apiPathPrefix = "/api/" + this.apiPathPrefix;
  }

  private getHeaders() {
    if (this._securityCache.loggedInUser == null) {
      return null;
    }

    var headers = new HttpHeaders();
    return headers.set(BaseApiService.UserTokenKey, this._securityCache.loggedInUser.id); //TODO: Change to token rather than id
    //headers = immutable
  }

  get loggedInUser() {
    return this._securityCache == null ? null : this._securityCache.loggedInUser;
  }

  hasUserRole(role: string) {
    let user = this.loggedInUser;
    if (user == null) {
      return false;
    }
    return user.roles.find(r => r.name.toLowerCase() == role.toLowerCase()) != null;
  }


  protected getApiPath(path: string): string {
    let apiPath = this.apiPathPrefix;
    if (path != null) {
      apiPath += path;
    }
    return apiPath;
  }

  protected getApiCall(path, makeUrlUnique: boolean = false, stringify: boolean = true): HttpRequestWithCallback {
    let url = this.apiPathPrefix + path;
    let call = new HttpRequestWithCallback(this._http, url, this.getHeaders());
    call.makeUrlUnique = makeUrlUnique;
    call.stringify = stringify;
    return call;
  }

  protected getAndCallback(path, callback, makeUrlUnique: boolean = false) {
    return this.getApiCall(path, makeUrlUnique).get(callback);
  }

  protected deleteAndCallback(path, callback, makeUrlUnique: boolean = false) {
    return this.getApiCall(path, makeUrlUnique).delete(callback);
  }

  protected putAndCallback(path, data, callback, makeUrlUnique: boolean = false) {
    return this.getApiCall(path, makeUrlUnique).put(data, callback);
  }

  protected postAndCallback(path, data, callback, makeUrlUnique: boolean = false, stringify: boolean = true) {
    return this.getApiCall(path, makeUrlUnique, stringify).post(data, callback);
  }

  isLoggedIn(loggedInCallBack, notLoggedInCallBack = null) {
    let self = this;

    let callBack = (result) => {
      self._securityCache.loginResult = result;
      if (result.isLoggedIn) {
        loggedInCallBack();
      } else if (notLoggedInCallBack != null) {
        notLoggedInCallBack();
      }
    }

    //if (self._securityCache.loginResult != null) {
      //return callBack(self._securityCache.loginResult);
    //}

    return this.getAndCallback("isloggedin", callBack);
  }
}
