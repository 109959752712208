/// <reference path="../../node_modules/@angular/core/core.d.ts" />
import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ResetComponent } from "./views/reset.component";
import { DefaultViewComponent } from "./views/defaultview.component";
import { AccountModule } from "./account/account.module";
import { LoggedInModule } from "./logged-in/logged-in.module";
import { FormInputsModule } from "@shared/form/form-inputs.module";
import { SharedModule } from "@shared/shared.module";

import { AppConfig } from "@models/app-config";
import { Cache } from "@models/cache";
import { HttpHelper } from "@utils/http-helper";
import { SecurityCache } from "@models/security-cache";

import { AccountService } from "@services/account.service";
import { BreadcrumbService } from "@services/breadcrumb.service";

import { DocumentWrapper, ScreenWrapper, WindowWrapper } from "./models/wrappers";

export function getWindow() { return window; }
export function getDocument() { return document; }
export function getScreen() { return screen; }

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    FormInputsModule,
    AppRoutingModule,
    AccountModule,
    LoggedInModule
  ],
  declarations: [
    AppComponent,
    DefaultViewComponent,
    ResetComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: AppConfig, useValue: environment },
    AccountService,
    BreadcrumbService,
    Cache,
    HttpHelper,
    SecurityCache,
    { provide: DocumentWrapper, useFactory: getDocument },
    { provide: ScreenWrapper, useFactory: getScreen },
    { provide: WindowWrapper, useFactory: getWindow }]
})
export class AppModule { }
