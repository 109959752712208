import { Permission } from "../models/permission";
import { SecurityCache } from "@models/security-cache";

export class Sitemap {
  nodes: Array<SitemapNode> = [];

  static isMasterUser(roles: Array<any>) {
    return this.userHasRole(roles, "Master");
  }

  static userHasRole(userRoles: Array<any>, role: string) {
    if (userRoles == null || userRoles.length == 0) {
      return false;
    }

    return userRoles.find(r => r.name == role) != null;
  }

  filter(securityCache: SecurityCache) {

    var parents = new Array<SitemapNode>();

    //if (userRoles == null || userRoles.length == 0) {
    //  return parents;
    //}

    let isNullOrEmpty = (text: string) => {
      return text == null || text.length == 0;
    }

    for (let parentNode of this.nodes) {
      if (!parentNode.canSeeNode(securityCache)) {
        continue;
      }

      var visibleNodes = parentNode.children.filter(n => n.canSeeNode(securityCache));

      var hasNoUrlOrAction = isNullOrEmpty(parentNode.url) && isNullOrEmpty(parentNode.action);

      if (visibleNodes.length == 0 && hasNoUrlOrAction) {
        continue;
      }

      var parent = <SitemapNode>{
        name: parentNode.name,
        url: parentNode.url,
        action: parentNode.action,
        icon: parentNode.icon,
        roles: parentNode.roles,
        children: visibleNodes
      };

      if (hasNoUrlOrAction) {
        for (let child of parent.children) {
          if (child.isDefault) {
            parent.url = child.url;
          }
        }
      }

      parents.push(parent);
    }

    return parents;
  }

  constructor(sitemap = null) {
    if (sitemap == null) {
      return;
    }

    for (let node of sitemap.nodes) {
      this.nodes.push(node);
    }
  }
}

export class SitemapNode {
  key: string;
  name: string;
  url: string;
  action: string;
  icon: string;
  roles: Array<string> = [];
  children: Array<SitemapNode> = [];
  isDefault: boolean;
  isHidden: boolean;

  constructor(
    key: string,
    name: string,
    icon: string = null,
    url: string = null,
    roles: Array<string> = [],
    action: string = null,
    isDefault: boolean = false,
    isHidden: boolean = false) {
    this.key = key;
    this.name = name;
    this.icon = icon;
    this.url = url;
    this.roles = roles;
    this.action = action;
    this.isDefault = isDefault;
    this.isHidden = isHidden;
  }

  public canSeeNode(securityCache: SecurityCache) {
    if (this.key === "help") {
      return true;
    }

    return securityCache.hasPermissionRegardlessOfBlackBox(this.key, Permission.read);
    //if (permissions == null) {
    //  return false;
    //}

    //var userPermissions = permissions[this.key];
    //if (userPermissions == null) {
    //  return false;
    //}

    //for (let up in userPermissions) {
    //  if (parseInt(up) === Permission.read) {
    //    return true;
    //  }
    //}

    //return false;
  }

  get visibleChildren() {
    return this.children.filter(x => !x.isHidden);
  }
}

export class BlackBoxSitemap {

  constructor(environment) {
    let site = new Sitemap();

    let management = new SitemapNode("management", "Management", null, "/logged-in/management");
    site.nodes.push(management);

    let tools = new SitemapNode("tools", "Tools", null, "/logged-in/tools");
    tools.children = [
      new SitemapNode("tools.json-tester", "JSON Tester", null, "/logged-in/tools/json-tester"),
      new SitemapNode("tools.legacy-tester", "Legacy XML Tester", null, "/logged-in/tools/xml-tester")
    ];
    site.nodes.push(tools);

    let admin = new SitemapNode("admin", "Admin", null, "/logged-in/admin/logs");
    admin.children = [
      new SitemapNode("admin.logs", "Logs", null, "/logged-in/admin/logs"),
      new SitemapNode("admin.user", "Users", null, "/logged-in/admin/user"),
      new SitemapNode("tools.restart", "Restart", null, "/logged-in/admin/restart")
    ];
    site.nodes.push(admin);

    site.nodes.push(new SitemapNode("error-logs", "Error Logs", null, "/logged-in/logs"));

    site.nodes.push(new SitemapNode("help", "Help", null, "/logged-in/help"));

    this.Sitemap = site;
  }

  public Sitemap: Sitemap;
}
