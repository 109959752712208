import { NgModule } from "@angular/core";

import { LoggedInRoutingModule } from "./logged-in-routing.module";

import { LoggedInComponent } from "./logged-in.component";
import { DefaultLoggedInComponent } from "./logged-in-default.component";
import { HelpIndexComponent } from "./help/help-index.component"
import { SimpleLogInFormComponent } from "../account/simple-login-form.component";
import { SharedModule } from "@shared/shared.module";

@NgModule({
  imports: [LoggedInRoutingModule, SharedModule],
  declarations: [
    LoggedInComponent,
    DefaultLoggedInComponent,
    HelpIndexComponent,
    SimpleLogInFormComponent
  ]
})

export class LoggedInModule { }
