import { Component } from "@angular/core";
import { ButtonWithSpinnerComponent } from "@shared/button-with-spinner.component";
import { AccountService } from "@services/account.service";

@Component({
  selector: "app-account-password-forgotten",
  templateUrl: "./forgotten-password.component.html"
})

export class ForgottenPasswordComponent {
  request = { username: "", email: "" };

  constructor(private _accountService: AccountService) {
  }

  click(button: ButtonWithSpinnerComponent) {
    var callback = (result) => {
      button.stopSpinning();
      if (result) {
        UIkit.modal.alert("Email sent...");
      } else {
        UIkit.modal.alert("Failed...");
      }
    };

    if ((this.request.username != null || this.request.username.length > 0) || (this.request.email != null && this.request.email.length > 0)) {
      this._accountService
        .resetPassword(this.request, callback)
        .catch(reason => {
          button.stopSpinning();
          UIkit.modal.alert("There has been an error resetting your password");
        });
    } else {
      UIkit.modal.alert("Please enter a username or email address");
    }
  }
}
